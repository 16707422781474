<template>
  <div class="main-content">
    <div v-if="isLoading" class="loading_page spinner spinner-primary mr-3"></div>
    <b-row v-if="!isLoading">
      <b-col md="12">
        <b-card class="card mb-30" header-bg-variant="transparent ">
          <b-tabs active-nav-item-class="nav nav-tabs" content-class="mt-3" @changed="tabChanged">
            <b-tab :title="$t('Basic Information')">
              <validation-observer ref="Edit_Employee" v-if="!isLoading">
                <b-form @submit.prevent="Submit_Employee" enctype="multipart/form-data">
                  <b-row>
                    <b-col md="12">
                      <b-card>
                        <b-row>
                          <!-- FirstName -->
                          <b-col md="4" class="mb-2">
                            <validation-provider name="FirstName" :rules="{ required: true }" v-slot="validationContext">
                              <b-form-group :label="$t('First Name') + ' ' + '*'">
                                <b-form-input
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="FirstName-feedback"
                                  label="FirstName"
                                  :placeholder="$t('First Name')"
                                  v-model="employee.firstname"
                                ></b-form-input>
                                <b-form-invalid-feedback id="FirstName-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <!-- LastName -->
                          <b-col md="4" class="mb-2">
                            <validation-provider name="LastName" :rules="{ required: true }" v-slot="validationContext">
                              <b-form-group :label="$t('Last Name') + ' ' + '*'">
                                <b-form-input
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="LastName-feedback"
                                  label="Last Name"
                                  :placeholder="$t('Last Name')"
                                  v-model="employee.lastname"
                                ></b-form-input>
                                <b-form-invalid-feedback id="LastName-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <!-- Gender -->
                          <b-col md="4" class="mb-2">
                            <validation-provider name="Gender" :rules="{ required: true }">
                              <b-form-group slot-scope="{ valid, errors }" :label="$t('Gender') + ' ' + '*'">
                                <v-select
                                  :class="{ 'is-invalid': !!errors.length }"
                                  :state="errors[0] ? false : valid ? true : null"
                                  v-model="employee.gender"
                                  :reduce="label => label.value"
                                  :placeholder="$t('Choose Gender')"
                                  :options="[
                                    { label: 'Male', value: 'male' },
                                    { label: 'Female', value: 'female' },
                                  ]"
                                ></v-select>
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <!-- Family_status -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Family status')">
                              <v-select
                                v-model="employee.marital_status"
                                :reduce="label => label.value"
                                :placeholder="$t('Choose Family status')"
                                @input="Selected_Family_status"
                                :options="[
                                  { label: 'Married', value: 'married' },
                                  { label: 'Single', value: 'single' },
                                  { label: 'Divorced', value: 'divorced' },
                                ]"
                              ></v-select>
                            </b-form-group>
                          </b-col>

                          <!-- Employment_type -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Employment type')">
                              <v-select
                                v-model="employee.employment_type"
                                :reduce="label => label.value"
                                :placeholder="$t('Select Employment type')"
                                @input="Selected_Employment_type_Employee"
                                :options="[
                                  { label: 'Full-time', value: 'full_time' },
                                  { label: 'Part-time', value: 'part_time' },
                                  { label: 'Self-employed', value: 'self_employed' },
                                  { label: 'Freelance', value: 'freelance' },
                                  { label: 'Contract', value: 'contract' },
                                  { label: 'Internship', value: 'internship' },
                                  { label: 'Apprenticeship', value: 'apprenticeship' },
                                  { label: 'Seasonal', value: 'seasonal' },
                                ]"
                              ></v-select>
                            </b-form-group>
                          </b-col>

                          <!-- Birth_date  -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Birth date')">
                              <Datepicker
                                id="birth_date"
                                name="birth_date"
                                :placeholder="$t('Enter Birth date')"
                                v-model="employee.birth_date"
                                input-class="form-control back_important"
                                format="yyyy-MM-dd"
                                @closed="employee.birth_date = formatDate(employee.birth_date)"
                              >
                              </Datepicker>
                            </b-form-group>
                          </b-col>

                          <!-- Email_Address -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Email Address')">
                              <b-form-input label="Email_Address" :placeholder="$t('Enter email address')" v-model="employee.email"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <!-- country -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Country')">
                              <b-form-input label="country" :placeholder="$t('Enter Country')" v-model="employee.country"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <!-- City -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('City')">
                              <b-form-input label="City" :placeholder="$t('Enter City')" v-model="employee.city"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <!-- Province -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Province/District')">
                              <b-form-input label="Province/District" :placeholder="$t('Enter Province/District')" v-model="employee.province"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <!-- Address -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Address')">
                              <b-form-input label="Address" :placeholder="$t('Enter Address')" v-model="employee.address"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <!-- Zip_code -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Zip/Pin code')">
                              <b-form-input label="zipcode" :placeholder="$t('Enter Zip/Pin code')" v-model="employee.zipcode"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <!-- phone -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Phone')">
                              <b-form-input label="phone" :placeholder="$t('Enter Phone Number')" v-model="employee.phone"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <!-- joining_date  -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Joining Date')">
                              <Datepicker
                                id="joining_date"
                                name="joining_date"
                                :placeholder="$t('Enter joining date')"
                                v-model="employee.joining_date"
                                input-class="form-control back important"
                                format="yyyy-MM-dd"
                                @closed="employee.joining_date = formatDate(employee.joining_date)"
                              >
                              </Datepicker>
                            </b-form-group>
                          </b-col>

                          <!-- Leaving_Date  -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Leaving Date')">
                              <Datepicker
                                id="leaving_date"
                                name="leaving_date"
                                :placeholder="$t('Enter Leaving Date')"
                                v-model="employee.leaving_date"
                                input-class="form-control back_important"
                                format="yyyy-MM-dd"
                                @closed="employee.leaving_date = formatDate(employee.leaving_date)"
                              >
                              </Datepicker>
                            </b-form-group>
                          </b-col>

                          <!-- Annual_Leave -->
                          <b-col md="4" class="mb-2">
                            <validation-provider name="total_leave" :rules="{ required: true }" v-slot="validationContext">
                              <b-form-group :label="$t('Annual Leave') + ' ' + '*'">
                                <b-form-input
                                  :state="getValidationState(validationContext)"
                                  aria-describedby="total_leave-feedback"
                                  label="total_leave"
                                  :placeholder="$t('Enter Annual Leave')"
                                  v-model="employee.total_leave"
                                ></b-form-input>
                                <b-form-invalid-feedback id="total_leave-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <!-- Remaining_leave -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Remaining leave')">
                              <b-form-input disabled="disabled" label="remaining_leave" v-model="employee.remaining_leave"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <!-- Department -->
                          <b-col md="4" class="mb-2">
                            <validation-provider name="Department" :rules="{ required: true }">
                              <b-form-group slot-scope="{ valid, errors }" :label="$t('Department') + ' ' + '*'">
                                <v-select
                                  :class="{ 'is-invalid': !!errors.length }"
                                  :state="errors[0] ? false : valid ? true : null"
                                  v-model="employee.department_id"
                                  class="required"
                                  required
                                  @input="Selected_Department"
                                  :placeholder="$t('Department')"
                                  :reduce="label => label.value"
                                  :options="departments.map(departments => ({ label: departments.department, value: departments.id }))"
                                />
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <!-- Designation -->
                          <b-col md="4" class="mb-2">
                            <validation-provider name="Designation" :rules="{ required: true }">
                              <b-form-group slot-scope="{ valid, errors }" :label="$t('Designation') + ' ' + '*'">
                                <v-select
                                  :class="{ 'is-invalid': !!errors.length }"
                                  :state="errors[0] ? false : valid ? true : null"
                                  v-model="employee.designation_id"
                                  class="required"
                                  required
                                  @input="Selected_Designation"
                                  :placeholder="$t('Choose Designation')"
                                  :reduce="label => label.value"
                                  :options="designations.map(designations => ({ label: designations.designation, value: designations.id }))"
                                />
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <!-- Office_Shift -->
                          <b-col md="4" class="mb-2">
                            <validation-provider name="Office_Shift" :rules="{ required: true }">
                              <b-form-group slot-scope="{ valid, errors }" :label="$t('Office Shift') + ' ' + '*'">
                                <v-select
                                  :class="{ 'is-invalid': !!errors.length }"
                                  :state="errors[0] ? false : valid ? true : null"
                                  v-model="employee.office_shift_id"
                                  class="required"
                                  required
                                  @input="Selected_Office_shift"
                                  :placeholder="$t('Choose Office Shift')"
                                  :reduce="label => label.value"
                                  :options="office_shifts.map(office_shifts => ({ label: office_shifts.name, value: office_shifts.id }))"
                                />
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>

                          <!-- Hourly_rate -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Hourly rate')">
                              <b-form-input label="Hourly_rate" :placeholder="$t('Enter Hourly rate')" v-model="employee.hourly_rate"></b-form-input>
                            </b-form-group>
                          </b-col>

                          <!-- Basic_salary -->
                          <b-col md="4" class="mb-2">
                            <b-form-group :label="$t('Basic salary')">
                              <b-form-input label="Basic_salary" :placeholder="$t('Enter Basic salary')" v-model="employee.basic_salary"></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <b-col md="12" class="mt-3">
                      <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
                      <div v-once class="typo__p" v-if="SubmitProcessing">
                        <div class="spinner sm spinner-primary mt-3"></div>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-tab>

            <b-tab :title="$t('Social Media')">
              <b-form @submit.prevent="Update_Employee_social">
                <b-row>
                  <b-col md="12">
                    <b-card>
                      <b-row>
                        <!-- Skype -->
                        <b-col md="4" class="mb-2">
                          <b-form-group>
                            <b-row class="d-flex justify-content-center align-items-center">
                              <div>
                                <img :src="require('@/assets/images/icons/social/icons8-skype.svg')" height="40" />
                              </div>
                              <div class="col-9">
                                <b-form-input v-model="employee.skype" name="skype" type="text" :placeholder="$t('Skype (Your Business Skype Link)')" />
                              </div>
                              <div>
                                <b-form-checkbox
                                  switch
                                  inline
                                  :checked="employee.skype != '' && employee.skype != null"
                                  :disabled="employee.skype == '' || employee.skype == null"
                                  size="md"
                                  class="mr-0 ml-0"
                                />
                              </div>
                            </b-row>
                          </b-form-group>
                        </b-col>

                        <!-- Facebook -->
                        <b-col md="4" cols="12" class="mb-2">
                          <b-form-group>
                            <b-row class="d-flex justify-content-center align-items-center">
                              <div>
                                <img :src="require('@/assets/images/icons/social/icons8-facebook.svg')" height="40" />
                              </div>
                              <div class="col-9">
                                <b-form-input v-model="employee.facebook" name="facebook" type="text" :placeholder="$t('Facebook (Your Business Facebook Link)')" />
                              </div>
                              <div>
                                <b-form-checkbox
                                  switch
                                  inline
                                  :checked="employee.facebook != '' && employee.facebook != null"
                                  :disabled="employee.facebook == '' || employee.facebook == null"
                                  size="md"
                                  class="mr-0 ml-0"
                                />
                              </div>
                            </b-row>
                          </b-form-group>
                        </b-col>

                        <!-- WhatsApp -->
                        <b-col md="4" cols="12" class="mb-2">
                          <b-form-group>
                            <b-row class="d-flex justify-content-center align-items-center">
                              <div>
                                <img :src="require('@/assets/images/icons/social/icons8-whatsapp.svg')" height="40" />
                              </div>
                              <div class="col-9">
                                <b-form-input v-model="employee.whatsapp" name="whatsapp" type="text" :placeholder="$t('Whatsapp (Your Business Whatsapp Link)')" />
                              </div>
                              <div>
                                <b-form-checkbox
                                  switch
                                  inline
                                  :checked="employee.whatsapp != '' && employee.whatsapp != null"
                                  :disabled="employee.whatsapp == '' || employee.whatsapp == null"
                                  size="md"
                                  class="mr-0 ml-0"
                                />
                              </div>
                            </b-row>
                          </b-form-group>
                        </b-col>

                        <!-- LinkedIn -->
                        <b-col md="4" cols="12" class="mb-2">
                          <b-form-group>
                            <b-row class="d-flex justify-content-center align-items-center">
                              <div>
                                <img :src="require('@/assets/images/icons/social/icons8-linkedin.svg')" height="40" />
                              </div>
                              <div class="col-9">
                                <b-form-input v-model="employee.linkedin" name="linkedin" type="text" :placeholder="$t('Linkedin (Your Business Linkedin Link)')" />
                              </div>
                              <div>
                                <b-form-checkbox
                                  switch
                                  inline
                                  :checked="employee.linkedin != '' && employee.linkedin != null"
                                  :disabled="employee.linkedin == '' || employee.linkedin == null"
                                  size="md"
                                  class="mr-0 ml-0"
                                />
                              </div>
                            </b-row>
                          </b-form-group>
                        </b-col>

                        <!-- Twitter -->
                        <b-col md="4" cols="12" class="mb-2">
                          <b-form-group>
                            <b-row class="d-flex justify-content-center align-items-center">
                              <div>
                                <img :src="require('@/assets/images/icons/social/icons8-twitter.png')" height="40" />
                              </div>
                              <div class="col-9">
                                <b-form-input v-model="employee.twitter" name="twitter" type="text" :placeholder="$t('Twitter (Your Twitter Link)')" />
                              </div>
                              <div>
                                <b-form-checkbox
                                  switch
                                  inline
                                  :checked="employee.twitter != '' && employee.twitter != null"
                                  :disabled="employee.twitter == '' || employee.twitter == null"
                                  size="md"
                                  class="mr-0 ml-0"
                                />
                              </div>
                            </b-row>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <b-button variant="primary" type="submit" :disabled="Submit_Processing_social"><i class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
                    <div v-once class="typo__p" v-if="Submit_Processing_social">
                      <div class="spinner sm spinner-primary mt-3"></div>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </b-tab>

            <!-- Experiences Table -->
            <b-tab :title="$t('Experiences')">
              <good-data-table
                key="experiences_table"
                ref="experiences"
                :button-label="$t('Add')"
                @add-new-info="New_Experience"
                :columns="columns_experiences"
                :no-serial="true"
                :fixParamsObj="{
                  id: $route.params.id,
                }"
                :total-column="'response.responseData.total'"
                :data-column="'response.responseData.data'"
                :api-endpoint="'/hrm/get_experiences_by_employee'"
              />
            </b-tab>

            <!-- accounts_bank Table -->
            <b-tab :title="$t('Bank Account')">
              <good-data-table
                key="accounts_table"
                ref="accounts"
                :button-label="$t('Add')"
                @add-new-info="New_Account"
                :columns="columns_accounts"
                :no-serial="true"
                :fixParamsObj="{
                  id: $route.params.id,
                }"
                :total-column="'response.responseData.total'"
                :data-column="'response.responseData.data'"
                :api-endpoint="'/hrm/get_accounts_by_employee'"
              />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

      <!-- Modal_Experience -->
      <validation-observer ref="Create_Experience">
        <b-modal hide-footer size="lg" v-model="Experience_Modal" :title="edit_mode_experience ? $t('Edit') : $t('Add')">
          <b-form @submit.prevent="Submit_Experience">
            <b-row>
              <!-- Title -->
              <b-col md="6" class="mb-2">
                <validation-provider name="Title" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group :label="$t('Title') + ' ' + '*'">
                    <b-form-input
                      :state="getValidationState(validationContext)"
                      aria-describedby="Title-feedback"
                      label="Title"
                      :placeholder="$t('Enter title')"
                      v-model="experience.title"
                    ></b-form-input>
                    <b-form-invalid-feedback id="Title-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Company_Name -->
              <b-col md="6" class="mb-2">
                <validation-provider name="Company_Name" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group :label="$t('Company Name') + ' ' + '*'">
                    <b-form-input
                      :state="getValidationState(validationContext)"
                      aria-describedby="Company_Name-feedback"
                      label="Company_Name"
                      :placeholder="$t('Enter Company Name')"
                      v-model="experience.company_name"
                    ></b-form-input>
                    <b-form-invalid-feedback id="Company_Name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Location -->
              <b-col md="6" class="mb-2">
                <b-form-group :label="$t('Location')">
                  <b-form-input label="Location" :placeholder="$t('Enter location')" v-model="experience.location"></b-form-input>
                </b-form-group>
              </b-col>

              <!-- start date -->
              <b-col md="6">
                <validation-provider name="start_date" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group :label="$t('Start Date') + ' ' + '*'">
                    <Datepicker
                      id="start_date"
                      name="start_date"
                      :placeholder="$t('Enter Start date')"
                      v-model="experience.start_date"
                      input-class="form-control back_important"
                      format="yyyy-MM-dd"
                      @closed="experience.start_date = formatDate(experience.start_date)"
                    ></Datepicker>
                    <b-form-invalid-feedback id="start_date-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- end date -->
              <b-col md="6">
                <validation-provider name="Finish_Date" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group :label="$t('Finish Date') + ' ' + '*'">
                    <Datepicker
                      id="end_date"
                      name="end_date"
                      :placeholder="$t('Enter Finish date')"
                      v-model="experience.end_date"
                      input-class="form-control back_important"
                      format="yyyy-MM-dd"
                      @closed="experience.end_date = formatDate(experience.end_date)"
                    ></Datepicker>
                    <b-form-invalid-feedback id="end_date-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Employment_type -->
              <b-col lg="6" md="6" sm="12" class="mb-2">
                <validation-provider name="Status" :rules="{ required: true }">
                  <b-form-group slot-scope="{ valid, errors }" :label="$t('Employment type') + ' ' + '*'">
                    <v-select
                      :class="{ 'is-invalid': !!errors.length }"
                      :state="errors[0] ? false : valid ? true : null"
                      v-model="experience.employment_type"
                      :reduce="label => label.value"
                      :placeholder="$t('Select Employment type')"
                      @input="Selected_Employment_type"
                      :options="[
                        { label: 'Full-time', value: 'full_time' },
                        { label: 'Part-time', value: 'part_time' },
                        { label: 'Self-employed', value: 'self_employed' },
                        { label: 'Freelance', value: 'freelance' },
                        { label: 'Contract', value: 'contract' },
                        { label: 'Internship', value: 'internship' },
                        { label: 'Apprenticeship', value: 'apprenticeship' },
                        { label: 'Seasonal', value: 'seasonal' },
                      ]"
                    ></v-select>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Description -->
              <b-col md="12">
                <b-form-group :label="$t('Description')">
                  <b-form-textarea rows="3" :placeholder="$t('Enter Description')" label="Description" v-model="experience.description"></b-form-textarea>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mt-3">
                <b-button variant="primary" type="submit" :disabled="Submit_Processing_Experience"><i class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
                <div v-once class="typo__p" v-if="Submit_Processing_Experience">
                  <div class="spinner sm spinner-primary mt-3"></div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </validation-observer>

      <!-- Modal_Account -->
      <validation-observer ref="Create_Account">
        <b-modal hide-footer size="lg" v-model="Account_Modal" :title="edit_mode_account ? $t('Edit') : $t('Add')">
          <b-form @submit.prevent="Submit_Account">
            <b-row>
              <!-- Title -->
              <b-col md="6" class="mb-2">
                <validation-provider name="Bank_Name" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group :label="$t('Bank Name') + ' ' + '*'">
                    <b-form-input
                      :state="getValidationState(validationContext)"
                      aria-describedby="Bank_Name-feedback"
                      label="Bank_Name"
                      :placeholder="$t('Enter Bank Name')"
                      v-model="account_bank.bank_name"
                    ></b-form-input>
                    <b-form-invalid-feedback id="Bank_Name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Bank_Branch -->
              <b-col md="6" class="mb-2">
                <validation-provider name="Bank_Branch" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group :label="$t('Bank Branch') + ' ' + '*'">
                    <b-form-input
                      :state="getValidationState(validationContext)"
                      aria-describedby="Bank_Branch-feedback"
                      label="Bank_Branch"
                      :placeholder="$t('Enter Bank Branch')"
                      v-model="account_bank.bank_branch"
                    ></b-form-input>
                    <b-form-invalid-feedback id="Bank_Branch-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Bank_Number -->
              <b-col md="6" class="mb-2">
                <validation-provider name="Bank_Number" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group :label="$t('Bank Number') + ' ' + '*'">
                    <b-form-input
                      :state="getValidationState(validationContext)"
                      aria-describedby="Bank_Number-feedback"
                      label="Bank_Number"
                      :placeholder="$t('Enter Bank Number')"
                      v-model="account_bank.account_no"
                    ></b-form-input>
                    <b-form-invalid-feedback id="Bank_Number-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Description -->
              <b-col md="12">
                <b-form-group :label="$t('Please provide any details')">
                  <b-form-textarea rows="3" :placeholder="$t('Enter Description')" label="Description" v-model="account_bank.note"></b-form-textarea>
                </b-form-group>
              </b-col>

              <b-col md="12" class="mt-3">
                <b-button variant="primary" type="submit" :disabled="Submit_Processing_Bank"><i class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
                <div v-once class="typo__p" v-if="Submit_Processing_Bank">
                  <div class="spinner sm spinner-primary mt-3"></div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </validation-observer>
    </b-row>
  </div>
</template>


<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { VueGoodTable } from 'vue-good-table'
const Fire = new Vue()
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
  metaInfo: {
    title: 'Details Employee',
  },
  components: {
    Datepicker,
    VueGoodTable,
    GoodDataTable,
  },
  data() {
    return {
      isLoading: true,
      SubmitProcessing: false,
      Submit_Processing_social: false,

      Experience_Modal: false,
      Account_Modal: false,

      Submit_Processing_Experience: false,
      edit_mode_experience: false,
      totalRows_experiences: '',
      limit_experiences: '10',
      experience_page: 1,

      Submit_Processing_Bank: false,
      edit_mode_account: false,
      totalRows_accounts: '',
      limit_accounts: '10',
      account_page: 1,

      data: new FormData(),
      experiences: [],
      companies: [],
      departments: [],
      designations: [],
      office_shifts: [],
      roles: {},
      employee: {},
      experience: {
        title: '',
        company_name: '',
        employment_type: '',
        location: '',
        start_date: '',
        end_date: '',
        description: '',
      },

      account_bank: {
        bank_name: '',
        bank_branch: '',
        account_no: '',
        note: '',
      },
    }
  },

  computed: {
    ...mapGetters(['currentUser']),
    routeNamePrefix: {
      get() {
        if (window.SuperApp.getters.isStore()) return 'shop'
        if (window.SuperApp.getters.isProvider()) return 'provider'
      },
    },
    columns_experiences() {
      return [
        {
          label: this.$t('Title'),
          field: 'title',
          sortable: false,
        },
        {
          label: this.$t('Start Date'),
          field: 'start_date',
          sortable: false,
        },
        {
          label: this.$t('Finish Date'),
          field: 'end_date',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Experience(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Experience(props)
                  }
                })
              },
            },
          ],
        },
      ]
    },
    columns_accounts() {
      return [
        {
          label: this.$t('Bank Name'),
          field: 'bank_name',
          sortable: false,
        },
        {
          label: this.$t('Bank Branch'),
          field: 'bank_branch',
          sortable: false,
        },
        {
          label: this.$t('Bank Number'),
          field: 'account_no',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Account(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Account(props)
                  }
                })
              },
            },
          ],
        },
      ]
    },
  },

  methods: {
    //------------- Submit Validation Edit Employee
    Submit_Employee() {
      this.$refs.Edit_Employee.validate().then(success => {
        if (!success) {
          showErrorNotification(this, 'Please fill the form correctly')
        } else {
          this.Edit_Employee()
        }
      })
    },

    //------ Validation State
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    formatDate(d) {
      var m1 = d.getMonth() + 1
      var m2 = m1 < 10 ? '0' + m1 : m1
      var d1 = d.getDate()
      var d2 = d1 < 10 ? '0' + d1 : d1
      return [d.getFullYear(), m2, d2].join('-')
    },

    Selected_Company(value) {
      if (value === null) {
        this.employee.department_id = ''
        this.employee.designation_id = ''
        this.employee.office_shift_id = ''
      }
      this.departments = []
      this.designations = []
      this.employee.department_id = ''
      this.employee.designation_id = ''
      this.employee.office_shift_id = ''
      this.Get_departments_by_company()
      this.Get_office_shift_by_company()
    },

    Selected_Department(value) {
      if (value === null) {
        this.employee.department_id = ''
        this.employee.designation_id = ''
      }
      this.designations = []
      this.employee.designation_id = ''
      this.Get_designations_by_department(value)
    },

    Selected_Designation(value) {
      if (value === null) {
        this.employee.designation_id = ''
      }
    },

    Selected_Gender(value) {
      if (value === null) {
        this.employee.gender = ''
      }
    },

    Selected_Family_status(value) {
      if (value === null) {
        this.employee.marital_status = ''
      }
    },

    Selected_Employment_type_Employee(value) {
      if (value === null) {
        this.employee.employment_type = ''
      }
    },
    Selected_Office_shift(value) {
      if (value === null) {
        this.employee.office_shift_id = ''
      }
    },

    //---------------------- Get_departments_by_company ------------------------------\\
    Get_departments_by_company() {
      axios.get(apiUrl + '/core/get_departments_by_company').then(({ data }) => (this.departments = data))
    },

    //---------------------- Get designations by department ------------------------------\\
    Get_designations_by_department(value) {
      axios.get(apiUrl + '/core/get_designations_by_department?id=' + value).then(({ data }) => (this.designations = data))
    },

    //---------------------- Get_office_shift_by_company ------------------------------\\
    Get_office_shift_by_company() {
      axios.get(apiUrl + '/core/get_office_shift_by_company').then(({ data }) => (this.office_shifts = data))
    },

    //------------------------------ Show Details -------------------------\\
    Get_Details() {
      let id = this.$route.params.id
      axios
        .get(`${apiUrl}/employees/${id}`)
        .then(response => {
          this.employee = response.data.employee
          this.companies = response.data.companies
          this.departments = response.data.departments
          this.designations = response.data.designations
          this.office_shifts = response.data.office_shifts

          this.isLoading = false
        })
        .catch(response => {
          this.isLoading = false
        })
    },

    //------------------------------ Create new Employee ------------------------------\\
    Edit_Employee() {
      // Start the progress bar.
      var self = this
      self.SubmitProcessing = true

      // Send Data with axios
      axios
        .put(apiUrl + '/employees/' + this.employee.id, {
          firstname: self.employee.firstname,
          lastname: self.employee.lastname,
          country: self.employee.country,
          email: self.employee.email,
          gender: self.employee.gender,
          phone: self.employee.phone,
          birth_date: self.employee.birth_date,
          department_id: self.employee.department_id,
          designation_id: self.employee.designation_id,
          office_shift_id: self.employee.office_shift_id,
          joining_date: self.employee.joining_date,
          leaving_date: self.employee.leaving_date,
          marital_status: self.employee.marital_status,
          employment_type: self.employee.employment_type,
          city: self.employee.city,
          province: self.employee.province,
          address: self.employee.address,
          zipcode: self.employee.zipcode,
          hourly_rate: self.employee.hourly_rate,
          basic_salary: self.employee.basic_salary,
          total_leave: self.employee.total_leave,
        })
        .then(response => {
          // Complete the animation of theprogress bar.
          self.SubmitProcessing = false
          self.$router.push({ name: `${self.routeNamePrefix}-employees_list` })
          showSuccessNotification(self, 'Updated successfully!')
        })
        .catch(error => {
          // Complete the animation of theprogress bar.
          showErrorNotification(self, 'Invalid Data')
          self.SubmitProcessing = false
        })
    },

    //------------------------ Update Social Profile ---------------------------\\
    Update_Employee_social() {
      var self = this
      this.Submit_Processing_social = true
      axios
        .put(apiUrl + '/update_social_profile/' + this.employee.id, {
          facebook: this.employee.facebook,
          skype: this.employee.skype,
          whatsapp: this.employee.whatsapp,
          twitter: this.employee.twitter,
          linkedin: this.employee.linkedin,
        })
        .then(() => {
          this.Submit_Processing_social = false
          showSuccessNotification(this, 'Updated successfully!')
        })
        .catch(error => {
          this.Submit_Processing_social = false
          // Complete the animation of theprogress bar.
          showErrorNotification(this, 'Invalid Data')
        })
    },

    //------------------------------ Show Modal (Create Experience) -------------------------------\\
    New_Experience() {
      this.reset_Form_experience()
      this.edit_mode_experience = false
      this.Experience_Modal = true
    },

    //------------------------------ Show Modal (Edit Experience) -------------------------------\\
    Edit_Experience(experience) {
      this.edit_mode_experience = true
      this.reset_Form_experience()
      this.experience = experience
      this.Experience_Modal = true
    },

    Selected_Employment_type(value) {
      if (value === null) {
        this.experience.employment_type = ''
      }
    },

    //----------------------------- Reset_Form_experience---------------------------\\
    reset_Form_experience() {
      this.experience = {
        id: '',
        title: '',
        company_name: '',
        employment_type: '',
        location: '',
        start_date: '',
        end_date: '',
        description: '',
      }
    },

    //------------- Submit Validation Create & Edit Experience
    Submit_Experience() {
      this.$refs.Create_Experience.validate().then(success => {
        if (!success) {
          showErrorNotification(this, 'Please fill the form correctly')
        } else {
          if (!this.edit_mode_experience) {
            this.Create_Experience()
          } else {
            this.Update_Experience()
          }
        }
      })
    },

    //------------------------------- Create_Experience ------------------------\\
    Create_Experience() {
      var self = this
      self.Submit_Processing_Experience = true
      axios
        .post(apiUrl + '/work_experience', {
          title: self.experience.title,
          company_name: self.experience.company_name,
          employee_id: self.employee.id,
          location: self.experience.location,
          employment_type: self.experience.employment_type,
          start_date: self.experience.start_date,
          end_date: self.experience.end_date,
          description: self.experience.description,
        })
        .then(response => {
          this.Submit_Processing_Experience = false
          this.$refs.experiences.loadItems()
          this.Experience_Modal = false
          showSuccessNotification(this, 'Created successfully!')
        })
        .catch(error => {
          this.Submit_Processing_Experience = false
          showErrorNotification(this, 'Invalid Data')
        })
    },

    //------------------------------- Update_Experience ------------------------\\
    Update_Experience() {
      var self = this
      self.Submit_Processing_Experience = true
      axios
        .put(apiUrl + '/work_experience/' + self.experience.id, {
          title: self.experience.title,
          company_name: self.experience.company_name,
          employee_id: self.employee.id,
          location: self.experience.location,
          employment_type: self.experience.employment_type,
          start_date: self.experience.start_date,
          end_date: self.experience.end_date,
          description: self.experience.description,
        })
        .then(response => {
          this.Submit_Processing_Experience = false
          this.$refs.experiences.loadItems()
          this.Experience_Modal = false
          showSuccessNotification(this, 'Updated successfully!')
        })
        .catch(error => {
          this.Submit_Processing_Experience = false
          showErrorNotification(this, 'Invalid Data')
        })
    },

    //------------------------------- Remove_Experience ------------------------\\
    Remove_Experience(props) {
      axios
        .delete(apiUrl + '/work_experience/' + props.id)
        .then(() => {
          this.$refs.experiences.loadItems()
          showSuccessNotification(this, 'Deleted successfully!')
        })
        .catch(() => {
          showErrorNotification(this, 'Something went wrong while deleting item!')
        })
    },

    //------------- Submit Validation Create & Edit Account
    Submit_Account() {
      this.$refs.Create_Account.validate().then(success => {
        if (!success) {
          showErrorNotification(this, 'Please fill the form correctly')
        } else {
          if (!this.edit_mode_account) {
            this.Create_Account()
          } else {
            this.Update_Account()
          }
        }
      })
    },

    //------------------------------ Show Modal (Create Bank Account) -------------------------------\\

    New_Account() {
      this.reset_Form_bank_account()
      this.edit_mode_account = false
      this.Account_Modal = true
    },

    //------------------------------ Show Modal (Edit Bank Account) -------------------------------\\

    Edit_Account(account_bank) {
      this.edit_mode_account = true
      this.reset_Form_bank_account()
      this.account_bank = account_bank
      this.Account_Modal = true
    },

    //----------------------------- Reset_Form_Bank Account---------------------------\\

    reset_Form_bank_account() {
      this.account_bank = {
        id: '',
        bank_name: '',
        bank_branch: '',
        account_no: '',
        note: '',
      }
    },

    //------------------------------- Create Bank Account ------------------------\\
    Create_Account() {
      var self = this
      self.Submit_Processing_Bank = true
      axios
        .post(apiUrl + '/employee_account', {
          employee_id: self.employee.id,
          bank_name: self.account_bank.bank_name,
          bank_branch: self.account_bank.bank_branch,
          account_no: self.account_bank.account_no,
          note: self.account_bank.note,
        })
        .then(() => {
          this.Submit_Processing_Bank = false
          this.$refs.accounts.loadItems()
          this.Account_Modal = false
          showSuccessNotification(this, 'Created successfully!')
        })
        .catch(error => {
          this.Submit_Processing_Bank = false
          showErrorNotification(this, 'Invalid Data')
        })
    },

    //------------------------------- Update Bank Account ------------------------\\
    Update_Account() {
      var self = this
      self.Submit_Processing_Bank = true
      axios
        .put(apiUrl + '/employee_account/' + self.account_bank.id, {
          employee_id: self.employee.id,
          bank_name: self.account_bank.bank_name,
          bank_branch: self.account_bank.bank_branch,
          account_no: self.account_bank.account_no,
          note: self.account_bank.note,
        })
        .then(response => {
          this.Submit_Processing_Bank = false
          this.$refs.accounts.loadItems()
          this.Account_Modal = false
          showSuccessNotification(this, 'Updated successfully!')
        })
        .catch(error => {
          this.Submit_Processing_Bank = false
          showErrorNotification(this, 'Invalid Data')
        })
    },

    //------------------------------- Remove_Account ------------------------\\
    Remove_Account(props) {
      axios
        .delete(apiUrl + '/employee_account/' + props.id)
        .then(() => {
          this.$refs.accounts.loadItems()
          showSuccessNotification(this, 'Deleted successfully!')
        })
        .catch(() => {
          showErrorNotification(this, 'Something went wrong while deleting item!')
        })
    },
    tabChanged(ct){
      if(ct === 2){
        this.$refs.experiences.loadItems()
      }else if(ct === 3){
        this.$refs.accounts.loadItems()
      }
    }
  },

  //----------------------------- Created function------------------- \\

  mounted: function () {
    this.Get_Details()
    this.Get_departments_by_company()
    this.Get_office_shift_by_company()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
